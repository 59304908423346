import { shallowEqual, useSelector } from 'react-redux';
import { CenteredFlexWithSpacing, ColumnFlexWithPadding, FlexWithSpacing } from 'app/typography/flex';
import { GreyText, Header, TextRegularBig } from 'app/typography/text';
import { getAppealsActionsOptions } from 'app/helpers/appeals';
import * as AppealsSelectors from 'store/appeals/appeals.selectors';
import { selectSelectedAppealSupplierId } from 'store/appeals/appeals.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import { Menu } from 'app/components/shared';
import { OptionMenu } from 'app/models/common';
import { useAppealActions } from 'app/helpers/hooks/use-appeal-actions';
import { APPEAL_STATUS } from 'app/constants/appeals';
import React from 'react';
import { statusColor, statusIcon } from '../../../Appeals/components/AppealStatusTitle';
import {
  AppealInfoHeaderComponent,
  Container,
  DateCard,
  Dates,
  DocumentIcon,
  PdfWatchButton,
  StatusIcon,
  StyledClickableIcon,
  TextRow,
  Theme,
} from './styled';
import { AppealWorkEvaluation } from '../AppealWorkEvaluation';
import { AppealId, AppealRoadMap } from '../../../../models/appeal';
import { TabsPanel } from '../../../../components/TabsPanel/TabsPanel';
import { ModalService } from '../../../../services/modal-service/modal.service';
import { PdwViewerDialog } from '../PdfViewer/PdfViewer';
import { selectSupplierBySupplierId } from '../../../../../store/suppliers/suppliers.selectors';
import { environment } from '../../../../environment';
import { appealDatesColorsMap, appealDatesTitleMap } from '../../constants/appeal-dates.enum';
import { findNextAvailableDate, formatDateHelper, transformFields } from './helpers';
import { SourceDict } from '../../constants/sources';

type DateCardItemProps = {
  source: string;
  status: string;
  date: string;
  isGray: boolean;
  createdAt: string;
  isAlwaysFull: boolean;
  borderColor: string;
  nextDate?: string;
  responsible: string;
  dateFields: AppealRoadMap['fields'];
};

export const DateCardItem = React.memo<DateCardItemProps>(props => {
  const title = React.useMemo(() => {
    const { title: sourceTitle, icon } = SourceDict[props?.source];

    return props.status === APPEAL_STATUS.new ? (
      <FlexWithSpacing spacing="10px">
        {sourceTitle} {icon}
      </FlexWithSpacing>
    ) : (
      <p>{appealDatesTitleMap[props?.status]}</p>
    );
  }, [props?.source, props.status]);

  const allDates = React.useMemo(() => {
    return [{ value: props.date, name: title, responsible: props.responsible }, ...transformFields(props.dateFields)];
  }, [props.date, props.dateFields, props.responsible, title]);

  return (
    <CenteredFlexWithSpacing spacing="4px">
      {allDates?.map(dateObj => {
        const { value, name, responsible } = dateObj; // Извлекаем дату и заголовок

        return (
          <ColumnFlexWithPadding spacing="4px">
            <DateCard isAlwaysFull={props.isAlwaysFull} isGray={props.isGray} borderColor={props.borderColor}>
              {typeof name === 'string' ? <p>{name}</p> : name}
              <GreyText>{formatDateHelper(value, props.createdAt, props.isAlwaysFull)}</GreyText>
              <br />
              <GreyText>{responsible}</GreyText>
            </DateCard>
          </ColumnFlexWithPadding>
        );
      })}
    </CenteredFlexWithSpacing>
  );
});

type AppealInfoHeaderProps = {
  appealId: AppealId;
  activeTabIndex: number;
  onActiveTabChange: (index: number) => void;
};
export const AppealInfoHeader = React.memo<AppealInfoHeaderProps>(props => {
  const appeal = useSelector(AppealsSelectors.selectSelectedAppeal, shallowEqual);
  const appealStatusLabel = useSelector(AppealsSelectors.selectAppealStatusLabelById(appeal?.status || APPEAL_STATUS.new), shallowEqual);
  const appealsTypes = useSelector(ConfigurationSelectors.selectAppealTypes, shallowEqual);
  const actions = useSelector(ConfigurationSelectors.selectAppealsActions, shallowEqual);
  const appealIsPayment = useSelector(AppealsSelectors.selectSelectedAppealIsPayment, shallowEqual);
  const supplierId = useSelector(selectSelectedAppealSupplierId, shallowEqual);
  const supplier = useSelector(selectSupplierBySupplierId(supplierId), shallowEqual);

  const { dispatchAction } = useAppealActions(appeal);

  const appealActionsOptions: OptionMenu[] = React.useMemo(() => {
    if (!appeal) return [];
    return getAppealsActionsOptions(appeal.type, appeal.status, appeal.consumer, actions)
      ?.filter(option => Boolean(option.label))
      .map(option => ({
        label: option.label,
        callback: () => dispatchAction(option.value),
      }));
  }, [actions, appeal, dispatchAction]);

  const onPdfButtonClickHandler = React.useCallback(() => {
    if (!supplier?.reg_file) return;
    const link = environment.apiBaseUrl + supplier?.reg_file;
    ModalService.openModal(PdwViewerDialog, { link, onClose: () => {} });
  }, [supplier?.reg_file]);

  const tabsList = React.useMemo(
    () => [
      { title: 'Общая информация', index: 0 },
      appealIsPayment ? { title: 'Оплата', index: 1 } : null,
      { title: 'История изменений', index: 2 },
      { title: 'История звонков', index: 3 },
      { title: 'Файлы', index: 4 },
    ],
    [appealIsPayment]
  );

  const header = React.useMemo(() => {
    const label = appealsTypes.find(type => type.id === appeal?.type)?.label;

    return `${label ?? ''} №${appeal?.number ?? 0}`;
  }, [appeal?.number, appeal?.type, appealsTypes]);

  const dates = React.useMemo(() => {
    return (
      <Dates>
        {appeal?.roadmap?.map((appealRoadMap, index) => {
          if (!appeal) return undefined;
          const date = appealRoadMap?.created_on;

          if (!date) return undefined;

          const responsible = appealRoadMap?.created_by || 'Автоматически';

          const nextDate = findNextAvailableDate(appeal?.roadmap, index);

          const isGray = (nextDate && index < appeal?.roadmap?.length - 1) || false;

          const isAlwaysFull = appealRoadMap?.status !== APPEAL_STATUS.inTheWork;

          const borderColor = appealDatesColorsMap[appealRoadMap?.status];

          return (
            <DateCardItem
              key={index}
              source={appeal?.source}
              status={appealRoadMap?.status}
              date={date}
              isGray={isGray}
              createdAt={appeal?.createdAt}
              isAlwaysFull={isAlwaysFull}
              borderColor={borderColor}
              nextDate={nextDate}
              responsible={responsible}
              dateFields={appealRoadMap?.fields}
            />
          );
        })}
      </Dates>
    );
  }, [appeal]);

  return (
    <Container>
      <AppealInfoHeaderComponent>
        <FlexWithSpacing spacing="30px">
          <FlexWithSpacing spacing="10px">
            <ColumnFlexWithPadding spacing="8px">
              <TextRow spacing="16px ">
                <Header>{header}</Header>
                <TextRow spacing="5px">
                  <StatusIcon color={statusColor[appeal?.status || 0]} icon={statusIcon[appeal?.status || 0]} />
                  <TextRegularBig>{appealStatusLabel}</TextRegularBig>
                </TextRow>
              </TextRow>

              {!!appeal?.subject && (
                <p>
                  <GreyText>Тема:</GreyText>&nbsp;&nbsp;
                  <Theme>{appeal?.subject}</Theme>
                </p>
              )}
            </ColumnFlexWithPadding>
          </FlexWithSpacing>
          {appeal?.status === APPEAL_STATUS.closed && <AppealWorkEvaluation workEvaluation={appeal?.workEvaluation} />}
        </FlexWithSpacing>

        <CenteredFlexWithSpacing spacing="15px">
          {supplier?.reg_file && (
            <PdfWatchButton onClick={onPdfButtonClickHandler}>
              <DocumentIcon />
            </PdfWatchButton>
          )}

          {!!appealActionsOptions.length && (
            <Menu options={appealActionsOptions}>
              <StyledClickableIcon icon="/assets/icons.svg#menu" />
            </Menu>
          )}
        </CenteredFlexWithSpacing>
      </AppealInfoHeaderComponent>

      {dates}

      <TabsPanel activeTabIndex={props.activeTabIndex} onTabIndexChange={props.onActiveTabChange} tabsList={tabsList} />
    </Container>
  );
});

AppealInfoHeader.displayName = 'AppealInfoHeader';
