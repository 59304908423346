import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { all, call, cancelled, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';
import Axios from 'axios';

import { WebPhoneDataService } from 'app/services/webphone-data-service/webphone-data.service';
import { Supplier } from 'app/models/supplier';
import {
  HangupOptions,
  IncomingCallOptions,
  IncomingConnectionEstablishedOptions,
  OutgoingCallOptions,
  WebPhoneService,
} from 'app/services/webphone.service';
import { ConsumersActions } from 'store/consumers';
import { CallsActions } from 'store/calls';
import { profileSelectors } from 'store/profile';
import { SuppliersSelectors } from 'store/suppliers';
import { NotificationsActions } from 'store/notifications';
import { ConfigurationSelectors } from 'store/configuration';
import { Profile } from 'app/models/profile/profile';
import { NotificationType } from 'app/models/notifications/notification';
import { convertConsumerToParticipant } from 'app/helpers';
import { webphoneActions } from './webphone.actions';
import { Consumer } from '../../app/models/consumer';
import { CallStatus, OutgoingRingingInitPayload, Participant } from './models';
import { webPhoneSelectors } from './webphone.selectors';
import { ParticipantSupplier } from './models/participant-supplier';
import { getUserNameFromSipUri } from '../../app/helpers/webphone/get-user-name-from-sip-uri';
import { CallDirection } from '../../app/models/call';
import { selectNewCall } from '../calls/calls.selectors';
import { CallsService } from '../../app/services/calls-service';

function* initIncomingRinging({ payload }: PayloadAction<IncomingCallOptions>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const participant = yield call(WebPhoneDataService.getParticipant, payload.callerPhone, cancelSource.token);
    const profile: Profile = yield select(profileSelectors.selectProfile);
    const userAgentName = profile?.sipUri ? getUserNameFromSipUri(profile?.sipUri) : '';
    const membersQueueCount = yield call(WebPhoneDataService.getQueueCount.bind(WebPhoneDataService), userAgentName, cancelSource.token);

    let supplier;
    if (payload.phoneLine) {
      supplier = yield call(getParticipantSupplierByPhoneLine, payload.phoneLine);
    }

    yield put(
      webphoneActions.InitIncomingRinging.success({
        participant,
        supplier,
        participantPhone: payload.callerPhone || 'Не определен',
        membersQueueCount,
      })
    );
  } catch (e: any) {
    yield put(webphoneActions.InitIncomingRinging.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* initOutgoingRinging({ payload }: PayloadAction<OutgoingRingingInitPayload>) {
  const cancelSource = Axios.CancelToken.source();
  try {
    const callStatus: CallStatus = yield select(webPhoneSelectors.selectCallStatus);
    const profile: Profile = yield select(profileSelectors.selectProfile);
    const userAgentName = profile?.sipUri ? getUserNameFromSipUri(profile?.sipUri) : '';
    const maybeAppealId = payload?.appealId;
    const maybeCallId = payload?.callId;

    const hasSipCredentials = !!profile?.sipPassword && !!profile?.sipUri;

    if (!hasSipCredentials) {
      return;
    }

    if (callStatus !== CallStatus.None) {
      yield put(
        NotificationsActions.AddNotification.init({
          body: 'Сначала завершите текущий звонок',
          type: NotificationType.Error,
        })
      );
      return;
    }

    const participant = yield call(WebPhoneDataService.getParticipant, payload.phone, cancelSource.token);

    const membersQueueCount = yield call(WebPhoneDataService.getQueueCount.bind(WebPhoneDataService), userAgentName, cancelSource.token);

    let supplier;
    if (payload.options?.prefix) {
      supplier = yield call(getParticipantSupplierByPhoneLine, payload.options?.prefix);
    }

    yield put(
      webphoneActions.InitOutgoingRinging.success({
        participant,
        supplier,
        participantPhone: payload.phone,
        membersQueueCount,
        appealId: maybeAppealId,
        callId: maybeCallId,
      })
    );

    WebPhoneService.call(payload.phone, payload.options);
  } catch (e: any) {
    yield put(webphoneActions.InitOutgoingRinging.failure(e));
  } finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel);
    }
  }
}

function* transferCall({ payload }: PayloadAction<OutgoingRingingInitPayload>) {
  WebPhoneService.transferCall(payload.phone, payload.options);
}

function* answer() {
  WebPhoneService.answer();
}

function* hangup() {
  WebPhoneService.hangupCall();
}

function* unregister() {
  WebPhoneService.unregister();
}

function* waitCallDurationLimitExceeded() {
  const callsAcceptingDelay = yield select(ConfigurationSelectors.selectCallsDuration);
  const { exceeded } = yield race({
    hangup: take(({ type }: AnyAction) => type === webphoneActions.hangup),
    exceeded: delay(callsAcceptingDelay * 1000),
  });

  if (exceeded) {
    yield put(webphoneActions.setIsCallDurationLimitExceeded(true));
  }
}

function* updateParticipantAfterUserCreation({ payload }: PayloadAction<Consumer>) {
  const callStatus: CallStatus = yield select(webPhoneSelectors.selectCallStatus);

  if (callStatus !== CallStatus.InTalk) {
    return;
  }

  const participant: Participant = convertConsumerToParticipant(payload);

  yield put(webphoneActions.setParticipant(participant));
}

function* handleIncomingConnectionEstablished({ payload: { callId, direction } }: PayloadAction<IncomingConnectionEstablishedOptions>) {
  const profile: Profile = yield select(profileSelectors.selectProfile);
  const supplier: ParticipantSupplier = yield select(webPhoneSelectors.selectParticipantSupplier);
  const participant: Participant = yield select(webPhoneSelectors.selectParticipant);
  const callerPhone: string = yield select(webPhoneSelectors.selectParticipantPhone);

  yield put(
    CallsActions.CreateCall.init({
      callId,
      dispatcherId: profile.id,
      type: direction,
      managecompanyId: supplier?.id,
      consumerId: participant?.consumerId,
      callerPhone,
    })
  );
}

function* handleOutComingConnectionEstablished({ payload: { callId, direction } }: PayloadAction<OutgoingCallOptions>) {
  const supplier: ParticipantSupplier = yield select(webPhoneSelectors.selectParticipantSupplier);
  const profile: Profile = yield select(profileSelectors.selectProfile);
  const participant: Participant = yield select(webPhoneSelectors.selectParticipant);
  const callerPhone: string = yield select(webPhoneSelectors.selectParticipantPhone);
  const appealId = yield select(webPhoneSelectors.selectAppealId);

  yield put(
    CallsActions.CreateCall.init({
      callId,
      dispatcherId: profile.id,
      type: direction,
      managecompanyId: supplier?.id,
      consumerId: participant?.consumerId,
      callerPhone,
      appealId,
    })
  );
}

function* handleSessionTermination({ payload: { direction } }: PayloadAction<HangupOptions>) {
  try {
    const openedAppealId: number | undefined = yield select(webPhoneSelectors.selectOpenedAppealId);
    const participant: Participant = yield select(webPhoneSelectors.selectParticipant);
    const newCall = yield select(selectNewCall);

    const maybeCall = yield direction === CallDirection.Incoming && call(CallsService.getCall, newCall?.id);
    const isParticipantHaveAppealId = participant?.appeals?.some(appeal => appeal?.id === openedAppealId);

    const shouldUpdateCallByAppealId =
      direction === CallDirection.Incoming && openedAppealId && isParticipantHaveAppealId && !maybeCall?.appeal?.id;

    yield shouldUpdateCallByAppealId &&
      put(
        CallsActions.UpdateCall.init({
          id: newCall?.id,
          updateCallDto: {
            appealId: openedAppealId,
          },
        })
      );

    yield put(webphoneActions.handleSessionTermination.success());
  } catch (error) {
    yield put(webphoneActions.handleSessionTermination.failure(error));
  }
}

function* getParticipantSupplierByPhoneLine(phoneLine: string) {
  const suppliers: Supplier[] = yield select(SuppliersSelectors.selectSuppliers);
  const supplier = suppliers.find(({ prefix }) => prefix === phoneLine);

  if (supplier) {
    return {
      id: supplier.id,
      phoneLine: supplier.prefix,
      name: supplier.name,
    } as ParticipantSupplier;
  }

  return undefined;
}

export default function* watcher() {
  yield all([
    takeLatest(webphoneActions.InitIncomingRinging.init, initIncomingRinging),
    takeLatest(webphoneActions.InitOutgoingRinging.init, initOutgoingRinging),
    takeLatest(webphoneActions.answer, answer),
    takeLatest([webphoneActions.answer, webphoneActions.establishedConnection], waitCallDurationLimitExceeded),
    takeLatest(webphoneActions.establishedConnection, handleOutComingConnectionEstablished),
    takeLatest(webphoneActions.hangup, hangup),
    takeLatest(webphoneActions.establishIncomingConnection, handleIncomingConnectionEstablished),
    takeLatest(webphoneActions.handleSessionTermination.init, handleSessionTermination),
    takeLatest(webphoneActions.unregister, unregister),
    takeLatest(ConsumersActions.CreateConsumer.success, updateParticipantAfterUserCreation),
    takeLatest(webphoneActions.transferCall, transferCall),
  ]);
}
