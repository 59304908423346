import { APPEAL_STATUS } from '../../../constants/appeals';

export enum AppealDatesField {
  CreatedAt = 'createdAt',
  ClosedAt = 'closedAt',
  PerformingEndedAt = 'performingEndedAt',
  PerformingStartedAt = 'performingStartedAt',
  PerformingStartedAtPlan = 'performingStartedAtPlan',
  PerformingEndedAtPlan = 'planned_end_date',
}

export const appealDatesTitleMap = {
  [APPEAL_STATUS.canceled]: 'Отменена',
  [APPEAL_STATUS.closed]: 'Закрыта',
  [APPEAL_STATUS.inTheWork]: 'В работе',
  [APPEAL_STATUS.postponed]: 'Отложена',
  [APPEAL_STATUS.completed]: 'Выполнена',
};

export const appealDatesColorsMap = {
  [APPEAL_STATUS.new]: '#EEC905',
  [APPEAL_STATUS.closed]: '#214E9D',
  [APPEAL_STATUS.canceled]: '#E7483D',
  [APPEAL_STATUS.postponed]: '#BDC2CE',
  [APPEAL_STATUS.inTheWork]: '#EE7A05',
  [APPEAL_STATUS.completed]: '#16A13D',
};

export const appealDatesFields: AppealDatesField[] = [
  AppealDatesField.CreatedAt,
  AppealDatesField.PerformingStartedAt,
  AppealDatesField.PerformingStartedAtPlan,
  AppealDatesField.PerformingEndedAtPlan,
  AppealDatesField.PerformingEndedAt,
  AppealDatesField.ClosedAt,
];
